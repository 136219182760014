import { Component, Vue } from 'vue-property-decorator'

//molds
import { Form } from '@/components/wrappers/molds/Form';

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'

//services
import { Alert } from '@/services/notification'

//wrappers
import {
	Box,
	WForm,
	WButton,
	WSubtitle,
	Loader
} from '@/components/wrappers';

//Vuex
const namespace = 'group';
import { Action, Getter } from 'vuex-class';
import { Group } from '@/store/modules/group/group';

@Component({
	components: {
		Box,
		WForm,
		WButton,
		WSubtitle,
		PrivateContent,
		Loader
	}
})
export default class NewGroup extends Vue {

	@Action('createGroup', { namespace }) createGroup: any;
	@Getter('newGroup', { namespace }) newGroup: any;
	@Getter('loader', { namespace }) isLoading: any;
	@Getter('error', { namespace }) error: any;

	alert = new Alert(this.$store)
	group: Group = null as any;

	clear = false

	mounted() {
		document.title = "Novo Grupo"
	}

	async dispatchForm(group: Group): Promise<void> {
		this.clear = false
		await this.createGroup(group)
		if(this.error) {
			this.alert.apiMessage("Não foi possível criar grupo.", this.error, "danger")
		}else{
			this.alert.success("Grupo cadastrado com sucesso!")
			this.group = this.newGroup
			this.clear = true
		}
	}

	goToMyGroup(): void {
		this.$router.push(`/my-group/${this.group.idGroup}`)
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			convertDate: true,
			grid: [
				[
					{
						fieldName: "name",
						type: "text",
						label: "Nome",
						placeholder: "Nome",
						maxlength: 100,
						required: true
					},
					{
						fieldName: "shortName",
						type: "text",
						label: "Abreviação",
						placeholder: "Abreviação",
						maxlength: 100,
						required: true
					},
					{
						fieldName: "status",
						type: "select",
						label: "Status",
						maxlength: 100,
						required: true,
						value:"1",
						options:[
							{
								label:"Desativado",
								value:"0"
							},
							{
								label:"Ativo",
								value:"1"
							},
						]
					},
				],
			]
		}
	}
}
